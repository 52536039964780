<template>
  <div class="scheme-contain">
    <sub-Menu></sub-Menu>
    <router-view></router-view>
  </div>
</template>
<script type="text/ecmascript-6">
import subMenu from 'pages/member/common/sub-menu';
export default {
	components: {
		'sub-Menu': subMenu,
	},
};
</script>
<style lang="css" scoped>
</style>
